import React from "react";
import { useParams, useNavigate } from "react-router-dom";

const TeamIntrodution = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[rgba(0,0,0,0.02)] flex flex-col items-center">
      {/* Logo Header */}
      <div className="w-[90%] max-w-2xl pt-16 pb-16">
        <img
          src="/images/logo.svg"
          alt="SSEEAA Logo"
          className="h-12 mx-auto cursor-pointer transition-transform duration-300 hover:opacity-80"
          onClick={() => navigate("/")}
        />
      </div>

      {/* Main Content */}
      <div className="w-[90%] max-w-3xl bg-white rounded-3xl px-6 md:px-20 py-8 md:py-14 shadow-sm">
        <div className="text-2xl md:text-3xl font-bold mt-8 mb-14 md:mb-16 flex flex-col items-left text-[rgba(0,0,0,1)] md:leading-[1.2]">
          <div>SSEEAA海海，</div>
          <div>解决中小商家跨境物流难题。</div>
        </div>

        <div className="mt-6 mb-10">
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            海海团队由多位在海外工作的华人组成，我们因为对国际贸易感兴趣在小红书上相识。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            我们深刻认识到，跨境电商为中小商家提供了将产品销往全球的机会。然而，复杂且不透明的头程运输、海外仓储和尾程派送，使中小商家的出海之路风险重重。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            我们坚信，国际贸易的小件化是大势所趋！帮助中小商家轻松将产品送到海外消费者手中是具有长期价值的事业。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            因此，我们决定建立一个跨境物流服务推荐平台，与业内最有责任感的人和公司合作，借助技术手段，让跨境物流变得简单透明。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            初期，我们将重点推荐全球的海外仓。未来，服务将逐步覆盖跨境物流全链条。
          </div>
        </div>

        <div className="mt-16 md:mt-20 mb-10">
          
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center justify-center text-[#4682B4]">
            我们的价值观
          </h2>

            <div className="flex flex-col text-justify mb-10 mt-10 leading-[1.65] md:leading-[1.65]">
              <span className="text-base md:text-lg font-bold text-[rgba(0,0,0,1)]">1️⃣ 站在商家一边</span>
              <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] mt-2">商家是我们唯一的客户，我们作为商家的代表，站在商家的角度查验服务商的可靠性，争取最优惠的价格。</span>
            </div>

            <div className="flex flex-col text-justify mb-10 mt-10 leading-[1.65] md:leading-[1.65]">
              <span className="text-base md:text-lg font-bold text-[rgba(0,0,0,1)]">2️⃣ 只赚一点点</span>
              <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] mt-2">跨境物流的所有费用都是商家的成本，我们希望服务尽量多的商家，通过每次服务赚尽量少的钱，为商家减负，帮商家提高价格竞争力。</span>
            </div>

            <div className="flex flex-col text-justify mb-10 mt-10 leading-[1.65] md:leading-[1.65]">
              <span className="text-base md:text-lg font-bold text-[rgba(0,0,0,1)]">3️⃣ 向违法行为说不</span>
              <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] mt-2">一些人通过科技单、水单等非法手段赚得盆满钵满，但是这些钱不安全、不长久，我们希望和大家一起做正确、可持续的事情。</span>
            </div>

        </div>

        <div className="mt-16 md:mt-20 mb-10">
          
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center justify-center text-[#4682B4]">
            我们的团队
          </h2>

          <h2 className="text-xs md:text-sm font-normal mt-10 flex items-center justify-center text-[rgba(0,0,0,0.2)]">
            技术
          </h2>

            <div className="w-[100%] flex flex-row items-center justify-left mt-10 mb-10">
              <img src="/images/1-1.jpg" className="h-10 md:h-12 w-10 md:w-12 mr-4 bg-[rgba(0,0,0,0.05)] rounded-3xl"/>
              <div className="flex flex-col items-left justify-center text-justify">
                <span className="text-sm md:text-base font-bold text-[rgba(0,0,0,1)]">佳澔</span>
                <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] leading-[1.4]">海海联合创始人。硅谷大厂工程师，Coding for someone we care about。</span>
              </div>
            </div>

            <div className="w-[100%] flex flex-row items-center justify-left mt-10 mb-10">
              <img src="/images/1-2.jpg" className="h-10 md:h-12 w-10 md:w-12 mr-4 bg-[rgba(0,0,0,0.05)] rounded-3xl"/>
              <div className="flex flex-col items-left justify-center text-justify">
                <span className="text-sm md:text-base font-bold text-[rgba(0,0,0,1)]">一凡</span>
                <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] leading-[1.4]">海海创始团队成员。硅谷大厂工程师，做长期且有意义的事。</span>
              </div>
            </div>

            <div className="w-[100%] flex flex-row items-center justify-left mt-10 mb-10">
              <img src="/images/1-3.jpg" className="h-10 md:h-12 w-10 md:w-12 mr-4 bg-[rgba(0,0,0,0.05)] rounded-3xl"/>
              <div className="flex flex-col items-left justify-center text-justify">
                <span className="text-sm md:text-base font-bold text-[rgba(0,0,0,1)]">邱昊</span>
                <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] leading-[1.4]">海海创始团队成员。西雅图大厂工程师，做务实的理想主义者。</span>
              </div>
            </div>

            <div className="w-[100%] flex flex-row items-center justify-left mt-10 mb-10">
              <img src="/images/1-4.jpg" className="h-10 md:h-12 w-10 md:w-12 mr-4 bg-[rgba(0,0,0,0.05)] rounded-3xl"/>
              <div className="flex flex-col items-left justify-center text-justify">
                <span className="text-sm md:text-base font-bold text-[rgba(0,0,0,1)]">陈蕾</span>
                <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] leading-[1.4]">海海创始团队成员。前硅谷Fintech数据科学家，专注创造价值，敢于改变世界。</span>
              </div>
            </div>

            <div className="w-[100%] flex flex-row items-center justify-left mt-10 mb-10">
              <img src="/images/1-5.jpg" className="h-10 md:h-12 w-10 md:w-12 mr-4 bg-[rgba(0,0,0,0.05)] rounded-3xl"/>
              <div className="flex flex-col items-left justify-center text-justify">
                <span className="text-sm md:text-base font-bold text-[rgba(0,0,0,1)]">昕澔</span>
                <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] leading-[1.4]">海海创始团队成员。硅谷大厂工程师，将技术与AI化作解决现实需求的高效引擎。</span>
              </div>
            </div>

          <h2 className="text-xs md:text-sm font-normal mt-10 flex items-center justify-center text-[rgba(0,0,0,0.2)]">
            业务
          </h2>

            <div className="w-[100%] flex flex-row items-center justify-left mt-10 mb-10">
              <img src="/images/2-1.jpg" className="h-10 md:h-12 w-10 md:w-12 mr-4 bg-[rgba(0,0,0,0.05)] rounded-3xl"/>
              <div className="flex flex-col items-left justify-center text-justify">
                <span className="text-sm md:text-base font-bold text-[rgba(0,0,0,1)]">一坤</span>
                <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] leading-[1.4]">海海联合创始人。万众创新时代成长起来的连续创业者，干没人干过的事。</span>
              </div>
            </div>

            <div className="w-[100%] flex flex-row items-center justify-left mt-10 mb-10">
              <img src="/images/2-2.jpg" className="h-10 md:h-12 w-10 md:w-12 mr-4 bg-[rgba(0,0,0,0.05)] rounded-3xl"/>
              <div className="flex flex-col items-left justify-center text-justify">
                <span className="text-sm md:text-base font-bold text-[rgba(0,0,0,1)]">梓仪</span>
                <span className="text-sm md:text-base font-normal text-[rgba(0,0,0,0.4)] leading-[1.4]">海海创始团队成员。华尔街金融分析师，和真诚的人一起dream a little dream。</span>
              </div>
            </div>

        </div>

        <div className="mt-16 md:mt-20 mb-10">
          
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center justify-center text-[#4682B4]">
            联系我们
          </h2>

          <h2 className="text-xs md:text-sm font-normal mt-10 flex flex-col items-center justify-center text-[rgba(0,0,0,0.2)]">
          <div className="w-[70%] font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-center mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            众人拾柴火焰高！
          </div>
          <div className="w-[60%] font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-center mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            无论你有任何建议或愿意提供任何帮助，都请给我们发邮件，我们对各类合作均保持开放态度。
          </div>
          <div className="w-[80%] font-bold text-[rgba(0,0,0,1)] text-sm md:text-base text-center mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
            friends@sseeaa.cc
          </div>
            <div className="flex items-center space-x-6">
                <a href="https://www.xiaohongshu.com/user/profile/6712b033000000001d020291?xsec_token=&xsec_source=pc_search" target="_blank" className="hover:opacity-80 cursor-pointer">
                <img src="/images/red.svg" alt="Contact Us" className="w-14 h-14" />
                </a>
            </div>
          </h2>

        </div>


      </div>

      {/* Button Footer */}
      <div className="w-[90%] max-w-2xl py-20 flex flex-col items-center">
        <a
          className="bg-[#4682B4] hover:opacity-80 text-[rgba(255,255,255,1)] font-bold py-3 px-8 rounded-full transition duration-300 cursor-pointer"
          onClick={() => navigate("/")}
        >
          返回
        </a>
      </div>

    </div>
  );
};

export default TeamIntrodution;
