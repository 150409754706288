import React from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

const warehouseUri = 'https://st2100000060204987.huoban.com/table_share?share_id=4300000369471544&secret=7FTQ4TtIs0I88siH18eL0WDrllIWA7TRsHnI0wTi&table_share_id=4100000026344929'

const Instruction = () => {
  const navigate = useNavigate();
  const { userType } = useParams();
  const location = useLocation()
  
  const isBetaVersion = location.search.includes('beta');

  let businessUri = 'https://st2100000060471271.huoban.com/table_share?share_id=4300000369541840&secret=sHs3hjiIhH8ibhPR300phvFi3RJIj383kjX1o880&table_share_id=4100000026399387'
  if (isBetaVersion) {
    businessUri = 'https://st2100000060305802.huoban.com/table_share?share_id=4300000369498191&secret=7FTQ4TtIs0I88siH18eL0WDrllIWA7TRsHnI0wTi&table_share_id=4100000026365682'
  }

  const content = {
    business: {
      title: '感谢你信任SSEEAA（海海）！',
      description1: '海海是为中小跨境商家推荐海外仓的平台，我们站在商家的角度查验海外仓及其实际控制人的可靠性，借助自研的推荐算法为商家推荐最合适、最便宜的海外仓。',
      description3: '海海不赚信息差的钱，商家和海外仓均不需要为推荐服务付费。',
      description2: '未来，海海计划通过一些高性价比的跨境物流周边服务实现可持续盈利。',
      one:'👍 海海的推荐方法',
      features: [
        {
          title: '可不可靠看人：',
          description: '海海推荐的不仅是海外仓，更是帮商家在海外打理货物的人。海海要求海外仓的实际控制人必须在海外仓内接受视频验真，并明确后续服务的唯一负责人。'

        },
        {
          title: '最合适：',
          description: '跨境商家需求各异，海海借助自研的推荐算法为不同品类、规模、需求的商家匹配最合适的海外仓。'
        },
        {
          title: '最便宜：',
          description: '海海要求合作海外仓按相同规则收费，根据需求快速计算出相关海外仓的精确报价并进行比价。'
        },
      ],
      two:'🚀 找仓流程',
      cooperationSteps: [
        {
          title: isBetaVersion ? '' : '联系海海：',
          description: isBetaVersion ? '' : '点击下方按钮提交联系方式，获得抢先体验机会。'
        },
        {
          title: '提交需求：',
          description: isBetaVersion ? '点击下方按钮提交需求，海海会在一天内通过微信与你核对需求。' : '海海会在一天内通过微信与你联系，协助你填写海外仓需求表单。'
        },
        {
          title: '推荐仓库：',
          description: '目前，推荐功能暂不支持商家自助使用，海海会在后台完成匹配并将推荐结果和报价微信发给你。'
        },
        {
          title: '达成合作：',
          description: '如果认可推荐结果和报价，海海会在微信中为你和海外仓唯一负责人拉群，你们可以在系统报价的基础上进一步洽谈，直至达成合作。'
        }
      ],
      button: isBetaVersion ? '提交需求' : '抢先体验'
    },
    warehouse: {
      title: '很高兴你有兴趣与SSEEAA（海海）合作！',
      description1: '海海是为中小跨境商家推荐海外仓的平台，通过视频会议等方式查验海外仓的可靠性，借助自研推荐算法为商家匹配最合适、最便宜的海外仓。',
      description3: '海海的推荐服务是永久免费的。',
      description2: '匹配成功后，海外仓可以直接与商家沟通、交易。',
      one:'👍 谁可以被推荐',
      features: [
        {
          title: '真实的人：',
          description: '海海推荐的不仅是海外仓，更是帮商家在海外打理货物的人。海海要求海外仓的实际控制人必须在海外仓内接受视频验真，并明确后续服务的唯一负责人。'
        },
        {
          title: '透明的价：',
          description: '海海联合数十家不同规模的海外仓制定了统一的报价规则，并基于此进行比价和推荐。海外仓可以自行设置收费标准，但必须严格执行，不得随意调整。'
        },
        {
          title: '限量推荐：',
          description: '由于目前商家数量有限，为了确保每个被推荐的海外仓都有收获，起步阶段海海仅在每个城市合作2~5个海外仓。'
        }
      ],
      two:'🚀 入驻流程',
      cooperationSteps: [
        {
          title: '报名：',
          description: '点击下方按钮，提交海外仓基本信息。'
        },
        {
          title: '验真：',
          description: '如果所在城市有空位，海海会在收到信息后一周内联系你，详细了解情况、协助完善收费标准和服务并进行视频验真；如果暂无空位，会待商家增多后再联系你。'
        },
        {
          title: '推荐：',
          description: '海海借助自研推荐算法为商家匹配最合适、最便宜的海外仓，并自动报价。海外仓和商家可以在系统报价的基础上进一步洽谈，直至达成合作。'
        }
      ],
      button: '开始入驻',
    },
  };

  const selectedContent = content[userType];

  return (
    <div className="min-h-screen bg-[rgba(0,0,0,0.02)] flex flex-col items-center">
      {/* Logo Header */}
      <div className="w-[90%] max-w-2xl pt-16 pb-16 transition-transform duration-300 hover:opacity-80">
        <img 
          src="/images/logo.svg" 
          alt="SSEEAA Logo" 
          className="h-12 mx-auto cursor-pointer"
          onClick={() => navigate('/')}
        />
      </div>

      {/* Main Content */}
      <div className="w-[90%] max-w-3xl bg-white rounded-3xl px-6 md:px-20 py-8 md:py-14 shadow-sm">

        <h1 className="text-sm md:text-base text-justify font-bold text-[rgba(0,0,0,1)] mb-6 mt-6 leading-[1.65] md:leading-[1.65]">
          {selectedContent.title}
        </h1>
        
        <p className="text-sm md:text-base text-justify font-normal text-[rgba(0,0,0,0.4)] mb-6 leading-[1.65] md:leading-[1.65]">
          {selectedContent.description1}
        </p>

        <p>
          <span className="text-sm md:text-base text-justify font-bold text-[rgba(0,0,0,1)] mb-6 leading-[1.65] md:leading-[1.65]">{selectedContent.description3}</span>
          <span className="text-sm md:text-base text-justify font-normal text-[rgba(0,0,0,0.4)] mb-6 leading-[1.65] md:leading-[1.65]">{selectedContent.description2}</span>
        </p>

        {/* Cooperation Process */}
        <div className="mt-10 md:mt-14 mb-6">
          
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            {selectedContent.one}
          </h2>

          <div>
            {selectedContent.features.map((feature, index) => (
              <div key={index} className="text-sm md:text-base text-justify mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
                <span className="font-bold text-[rgba(0,0,0,1)]">{feature.title}</span>
                <span className="font-normal text-[rgba(0,0,0,0.4)]">{feature.description}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="mt-10 md:mt-14 mb-6">
          
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            {selectedContent.two}
          </h2>

          <div>
            {selectedContent.cooperationSteps.map((step, index) => (
              <div key={index} className="text-sm md:text-base text-justify mb-2 mt-6 leading-[1.65] md:leading-[1.65]">
                <span className="font-bold text-[rgba(0,0,0,1)]">{step.title}</span>
                <span className="font-normal text-[rgba(0,0,0,0.4)]">{step.description}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Button Footer */}
      <div className="w-[90%] max-w-2xl py-20 flex flex-col items-center">
        <a
          href={userType === 'warehouse' ? warehouseUri : businessUri}
          target="_blank" rel="noopener noreferrer" className="bg-[#4682B4] hover:opacity-80 text-[rgba(255,255,255,1)] font-bold py-3 px-8 rounded-full transition duration-300"
        >
          {selectedContent.button}
        </a>
      </div>
    </div>
  );
};

export default Instruction;