import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const ProjectProgress = () => {
  const navigate = useNavigate();

  return (
    <div className="min-h-screen bg-[rgba(0,0,0,0.02)] flex flex-col items-center">
      {/* Logo Header */}
      <div className="w-[90%] max-w-2xl pt-16 pb-16">
        <img
          src="/images/logo.svg"
          alt="SSEEAA Logo"
          className="h-12 mx-auto cursor-pointer transition-transform duration-300 hover:opacity-80"
          onClick={() => navigate('/')}
        />
      </div>

      {/* Main Content */}
      <div className="w-[90%] max-w-3xl bg-white rounded-3xl px-6 md:px-20 py-8 md:py-14 shadow-sm">

        <div className="text-2xl md:text-3xl font-bold mt-8 mb-14 md:mb-16 flex flex-col items-left text-[rgba(0,0,0,1)] md:leading-[1.2]">
          <div>一步一个脚印，</div>
          <div>帮助中小商家跨越山海。</div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.12.20
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            海外仓推荐系统（Pre-launch）上线。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            从此，跨境商家可以通过SSEEAA.CC提交需求，在几分钟内获得最合适、最便宜的海外仓精确报价，海外仓也可以通过SSEEAA.CC入驻海海。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.12.14
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            开始与美国本土物流公司就尾程合作进行深入沟通，同时与行业排名前3的WMS达成了深度合作，对软件收费方式进行了创新。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.28
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            获得第一笔收入。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            一个商家希望海海帮忙对朋友推荐的一家海外仓进行电话验真，工作完成后，给了海海50元人民币红包。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.27
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            由海海牵头设计的「海外仓标准化报价单」亮相。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            此前两周，报价单经过了数十次修改，最终得到了大部分合作海外仓的认可，包括小规模家庭仓和数十万尺的商业仓库。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.24
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            除佳澔和一坤之外的前3位创始团队成员加入，海外仓推荐系统开始coding。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            分析了最初的100多组撮合案例后，决定在统一报价规则前暂停推荐海外仓，同时提高推荐系统开发的优先级。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.17
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            第一批22个合作海外仓招募完成，分布在9个国家。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            此前一周，海海在小红书上获得了100多个跨境商家和300多个海外仓的咨询，并决定先与少量商家、仓库进行磨合，流程跑通后再为更多商家提供服务。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.15
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            促成第一单！帮助一个江苏商家将洛杉矶另一个仓库中的货物移至「美速」海外仓。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.10
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            第一次通过视频会议对海外仓进行远程验真，是拉斯维加斯的「美西丹尼」家庭海外仓。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.02
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            第一次实地拜访海外仓，是洛杉矶的「美速」海外仓。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.11.01
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            小红书账号「SSEEAA - 0佣金推荐海外仓」开更。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            此前一天，佳澔和一坤为项目选定了中英文名并设计了Logo，SSEEAA海海寓意广阔的跨境物流业务。
          </div>
        </div>

        <div className="mt-6 mb-12">
          <h2 className="text-lg md:text-xl font-bold mb-4 flex items-center text-[#4682B4]">
            2024.09.19
          </h2>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
          硅谷大厂工程师佳澔和出海美国的创业者一坤在小红书上认识，讨论如何通过技术手段解决跨境物流难题。
          </div>
          <div className="font-normal text-[rgba(0,0,0,0.4)] text-sm md:text-base text-justify mb-4 mt-4 leading-[1.65] md:leading-[1.65]">
            此前一个月，佳澔在工作之余发现了跨境物流软件的创业机会，一坤撰写了名为「10个海外仓，8个二道贩子」的小红书热门笔记。
          </div>
        </div>

      </div>

      {/* Button Footer */}
      <div className="w-[90%] max-w-2xl py-20 flex flex-col items-center">
        <a
          className="bg-[#4682B4] hover:opacity-80 text-[rgba(255,255,255,1)] font-bold py-3 px-8 rounded-full transition duration-300 cursor-pointer"
          onClick={() => navigate("/")}
        >
          返回
        </a>
      </div>
    </div>
  );
};

export default ProjectProgress;