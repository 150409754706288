import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const Prelaunch = () => {
  const navigate = useNavigate();
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const location = useLocation()
  
  const isBetaVersion = location.search.includes('beta');

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const users = [
    {
      name: "business",
      buttonText: "寻找海外仓",
    },
    {
      name: "warehouse",
      buttonText: "海外仓入驻",
    },
  ];

  const handleUserClick = async (userType) => {
    setIsTransitioning(true);
    
    let next_page = `/detail/${userType}`
    if (isBetaVersion) {
        next_page = `/detail/${userType}?beta`
    }
    // Wait for animation to complete before navigating
    setTimeout(() => {
      navigate(next_page);
    }, 500); // 500ms matches the transition duration
  };

  const appHeight = () =>
    document.documentElement.style.setProperty(
      "--app-height",
      `${window.innerHeight}px`
    );
  window.addEventListener("resize", appHeight);
  appHeight();

  const handleNavigation = (path) => {
    setIsMenuOpen(false);
    navigate(path);
  };

  return (
    <div
      className={`h-[var(--app-height)] 
                  bg-cover bg-center bg-no-repeat 
                  bg-black/50
                  text-white
                  font-sans
                  transition-opacity duration-500 ease-in-out
                  overflow-y-hidden
                  ${isTransitioning ? "opacity-0" : "opacity-100"}`}
      style={{
        backgroundImage: `url("${
          isMobile
            ? "/images/background_mobile.jpg"
            : "/images/background_m.jpg"
        }")`,
        backgroundBlendMode: "overlay",
      }}
    >
      {/* Navigation */}
      <nav className="w-[90%] flex justify-between items-center pt-10 md:pt-16 mx-auto">
        <div className="flex justify-start items-center">
          <img
            src="/images/Logo_White.svg"
            alt="SSEEAA Logo"
            className="h-10 md:h-12"
            onClick={() => navigate("/")}
          />

          <div className="hidden md:flex md:flex-row ml-40">
            <a
              href="/team-introduction"
              className="hover:opacity-80 font-bold cursor-pointer"
            >
              关于海海
            </a>
            <a
              href="/project-progress"
              className="ml-20 hover:opacity-80 font-bold cursor-pointer"
            >
              项目进展
            </a>
          </div>
        </div>

        {/* Mobile Menu Button */}
        <button
          className="md:hidden z-50"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            {isMenuOpen ? (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            ) : (
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 6h16M4 12h16M4 18h16"
              />
            )}
          </svg>
        </button>

        {/* Mobile Menu Overlay */}

        <div
          className={`fixed inset-0 bg-black bg-opacity-80 md:hidden transition-opacity duration-300 ${
            isMenuOpen ? "opacity-100 visible z-40" : "opacity-0 invisible"
          }`}
          onClick={() => setIsMenuOpen(false)}
        >
          <div
            className={`fixed right-0 top-0 h-full w-64 bg-[#4682B4] transform transition-transform duration-300 ${
              isMenuOpen ? "translate-x-0" : "translate-x-full"
            } z-50`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col h-[100%] items-center justify-center text-lg space-y-8">
              <a
                href="/team-introduction"
                className="text-[rgba(255,255,255,1)] hover:opacity-90 font-bold"
                onClick={() => handleNavigation("/team-introduction")}
              >
                关于海海
              </a>

              <a
                href="/project-progress"
                className="text-[rgba(255,255,255,1)] hover:opacity-90 font-bold"
                onClick={() => handleNavigation("/project-progress")}
              >
                项目进展
              </a>
            </div>
          </div>
        </div>

        {/* Contact Us Icon - Hidden on mobile */}
        <div className="hidden md:flex items-center space-x-6">
          <a
            href="https://www.xiaohongshu.com/user/profile/6712b033000000001d020291?xsec_token=&xsec_source=pc_search"
            target="_blank"
            className="hover:opacity-80 cursor-pointer"
          >
            <img
              src="/images/Red_White.svg"
              alt="Contact Us"
              className="w-14 h-14"
            />
          </a>
        </div>
      </nav>

      {/* Hero Section */}
      <div
        className={`hidden min-h-[calc(100vh-200px)] md:flex flex-col items-center justify-center text-center 
      transition-transform duration-300 ease-in-out ${
        isTransitioning ? "transform translate-y-10" : "transform translate-y-0"
      }`}
      >
        <h1 className="font-bold text-6xl mb-4">海海推荐的不仅是海外仓</h1>
        <h2 className="font-bold text-6xl mb-20">
          更是帮商家在海外打理货物的人
        </h2>

        <div target="_blank" className="flex space-x-20">
          {users.map((user, index) => (
            <button
              key={index}
              onClick={() => handleUserClick(user.name)}
              className="text-xl font-bold bg-transparent border-4 border-white text-white px-20 py-4 hover:bg-[rgba(255,255,255,0.2)] duration-300 transform rounded-full"
            >
              {user.buttonText}
            </button>
          ))}
        </div>
      </div>

      {/* Mobile Hero Section */}
      <div
        className={`md:hidden w-[100%] flex flex-col items-center justify-center min-h-[calc(100vh-100px)] 
      transition-transform duration-300 ease-in-out ${
        isTransitioning ? "transform translate-y-10" : "transform translate-y-0"
      }`}
      >
        <h1 className="font-bold text-justify text-4xl w-[80%] mb-20">
          海海推荐的不仅是海外仓，更是帮商家在海外打理货物的人。
        </h1>

        <div
          target="_blank"
          className="flex space-y-4 flex-col items-center w-[80%]"
        >
          {users.map((user, index) => (
            <button
              key={index}
              onClick={() => handleUserClick(user.name)}
              className="text-lg font-bold bg-transparent border-4 border-white text-white w-[100%] py-2 hover:bg-[rgba(255,255,255,0.2)] duration-300 transform rounded-full"
            >
              {user.buttonText}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Prelaunch;
