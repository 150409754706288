import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Prelaunch from './components/layout/Prelaunch';
import Detail from './components/layout/Instruction';
import Team from './components/layout/TeamIntroduction';
import Progress from './components/layout/ProjectProgress';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Prelaunch />} />
        <Route path="/?beta" element={<Prelaunch />} />
        <Route path="/detail/:userType" element={<Detail />} />
        <Route path="/detail/:userType?beta" element={<Detail />} />
        <Route path="/team-introduction" element={<Team />} />
        <Route path="/project-progress" element={<Progress />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
